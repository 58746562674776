<template>
  <b-container class="py-5 px-4 px-md-5 container_monitors" fluid>
    <b-row class="mb-4">
      <b-col cols="12">
        <b-tabs>
          <!-- Main -->
          <b-tab title="Main" class="pt-5 pb-4 main" active>
            <b-container fluid class="px-lg-5">
              <!-- Details -->
              <b-row class="mb-lg-3">
                <b-col cols="12">
                  <h5 class="fw-bold">Details</h5>
                </b-col>
              </b-row>
              <!-- Description -->
              <b-row>
                <b-col cols="12" md="8" lg="6" xl="4">
                  <b-form-group label="Description" label-for="description" class="text-start mb-2" label-class="fw-bold mb-1">
                    <b-form-input id="description" type="text" placeholder="Description"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              
              <!-- Contact information -->
              <b-row class="mt-4 mb-md-2">
                <b-col cols="12">
                  <h5 class="fw-bold">Operators contained in this group</h5>
                </b-col>
              </b-row>
              <!-- Operator -->
              <b-row>
                <b-col cols="12" md="6" xl="auto">
                  <b-form-group class="mb-3">
                      <b-form-select v-model="form.operator" :options="operators" id="monitor" class="form-select">
                        <template #first>
                          <b-form-select-option :value="null" disabled>Operator</b-form-select-option>
                        </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="2" lg="auto" class="mb-4">
                  <b-button variant="primary" class="w-100">Add</b-button>
                </b-col>
              </b-row>
              <b-row v-for="(operator, index) in operators" :key="index" class="mb-2">
                <b-col cols="9" md="5" xl="3">
                  {{ operator.text }}
                </b-col>
                <b-col cols="auto">
                  <vue-fontawesome icon="close" size="1" color="#000" class="ms-3"></vue-fontawesome>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>

          <!-- Permissions -->
          <b-tab title="Permissions" class="pt-5 pb-4 main">
            <b-container fluid class="px-lg-5">
              <!-- Login information -->
              <b-row class="mb-lg-3">
                <b-col cols="12" class="mb-md-2">
                  <h5 class="fw-bold">System-wide permissions</h5>
                </b-col>
                <b-col cols="12" class="mb-md-2">
                  <b-container class="p-md-0" fluid>
                    <b-row>
                      <b-col cols="auto">
                        <b-form-checkbox></b-form-checkbox>
                      </b-col>
                      <b-col class="ps-0">
                        <p class="mb-0">Basic operator</p>
                        <p class="mb-0 fst-italic">The default permissions for an operator to be able to view monitors and dashboards.</p>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
                <b-col cols="12">
                  <b-container class="p-md-0" fluid>
                    <b-row>
                      <b-col cols="auto">
                        <b-form-checkbox></b-form-checkbox>
                      </b-col>
                      <b-col class="ps-0">
                        <p class="mb-0">Create alert definitions</p>
                        <p class="mb-0 fst-italic">Allow operators to create new alert definitions.</p>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
                <b-col cols="12">
                  <b-container class="p-md-0" fluid>
                    <b-row>
                      <b-col cols="auto">
                        <b-form-checkbox></b-form-checkbox>
                      </b-col>
                      <b-col class="ps-0">
                        <p class="mb-0">Create alert definitions</p>
                        <p class="mb-0 fst-italic">Operator(s) that will be contacted in case of technical changes or issues related to the account.</p>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
              
              <!-- Monitor permissions -->
              <b-row class="mt-md-4 mt-lg-5 mb-md-2">
                <b-col cols="12">
                  <h5 class="fw-bold">Monitor permissions</h5>
                </b-col>
              </b-row>
              <!-- Monitor -->
              <b-row>
                <b-col cols="auto">
                  <b-form-group label="Monitor" label-for="monitor" class="mb-3" label-class="fw-bold">
                      <b-form-select v-model="form.monitor" :options="monitors" id="monitor" class="form-select">
                        <template #first>
                          <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                        </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-container>
                    <b-row>
                      <b-col cols="12" class="w-100 d-flex" style="justify-content: space-between;">
                        <label>View data</label>
                        <label>View settings</label>
                        <label>Edit settings</label>
                        <label>Create and delete</label>
                      </b-col>
                      <b-col cols="12">
                        <div class="d-flex">
                          <b-form-input id="range-monitor" type="range" class="w-100"></b-form-input>
                          <vue-fontawesome icon="close" size="1" color="#000" class="ms-3"></vue-fontawesome>
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>

        </b-tabs>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="auto" class="mb-2 mb-md-0">
        <b-button variant="primary" type="submit" class="px-5 w-100">Save</b-button>
      </b-col>
      <b-col cols="12" md="auto">
        <b-button variant="secondary" type="submit" class="px-5 w-100">Cancel</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      form: {
        monitor: null,
        operator: null,
      },
      monitors: [
        { value: 'add_monitor', text: 'Add monitor' },
      ],
      operators: [
        { value: 1, text: 'Mateo Monsalve' },
        { value: 2, text: 'Yehison Gómez' },
        { value: 3, text: 'Mauricio Gutierrez' },
      ],
      load: false,
    }
  },
}
</script>
